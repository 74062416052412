 
 
import './ScriptureStyles.css';
import { setCookie, getCookie } from './BJCookies.js';
import { useState, useEffect, useRef } from 'react'


export function XImage(props) {
    const [src, setSrc] = useState([]);

    useEffect(() => {
        setSrc(props.imgSrc);
    }, []);

       
    return (
     
        <div>
            <img src={src} id={props.id} alt=""  /> 
            
            </div>

    )

}

//export function XRange(props) {
//    const [speed, setSpeed] = useState();
//    const [min, setMin] = useState();
//    const [max, setMax] = useState();
//    useEffect(
//        () => {
//            setSpeed(props.t);
//            setMin(props.min);
//            setMax(props.max);
//        },
//        [ ]
//    );
//    return (<div>
//        <input type="range"
//            className="spd"
//            min={min}
//            max={max}
//            value={speed}
//            onChange={(e) => {
//                const { value } = e.target;
//                setSpeed(parseInt(value, 10));
//                setCount(currentCount - 1);
//            }} />
//    </div>
        
//        )

//}
export function XImageSlider(props) {
    const [images, setImages] = useState([]);
    const [speed, setSpeed] = useState();
    const [boxHeight, setBoxHeight] = useState();
  const [timerId,setTimerId] = useState();
    const [width,setWidth ] = useState();  
    const [currentCount, setCount] = useState(props.size);
    const [w,setW ] = useState(false );
    var tId; 
    const timer = () => setCount(currentCount - 1);
    useEffect(
        () => {
           setImages(   props.images );
            setW(props.w);
            setSpeed(props.t);
            setWidth(props.width);
            setBoxHeight(props.BoxHeight);
   },
        [images ]
    );
    const Clock = () => {
      
      
  //      const [delay, setDelay] = useState();
        const timer = () => setCount(currentCount - 1);
        useEffect(
            () => {
               
              /*setSpeed(props.t);*/
                if (currentCount < 0) {
                    setCount(props.size);
                  
                }
               
               
     var id=    setInterval(timer, speed);
             setTimerId(id);
                return () => clearInterval(id);
          
            },
            [currentCount ]
        );
       
        return currentCount
     };
  
    return (<>
        <div className="col-lg-12   col-sm-12  no-gutters " >
            <div style={{height:'95%',overflow: 'auto' } }>
            <span className="speedSize" >
                <label  >
                    Slower
                    <input type="range"
                        className="spd"
                        min="500"
                        max="15000"
                        value={speed}
                        onChange={(e) => {
                            const { value } = e.target;
                            setSpeed(parseInt(value, 10));
                            setCount(currentCount-1 );
                        }} />
                    Faster    </label> </span>
     
            {w ? <span className="speedSize" >
                    <label >
                Smaller
                <input type="range"
                     className="wdt"
                    min="25"
                    max="300"
                    value={width}
                    onChange={(e) => {
                        const { value } = e.target;
                        setWidth(value);
                    }} />
                        Larger    </label> </span>   
                    
            : ""}</div>
            <img className="myImgBox animated fadeIn  " src={images[Clock()]} style={{ width: width + '%'} } />  
      
        </div></>)
}
/* {images.map((p, index) =>key={index}id={index} style={{ width: "600px", minHeight:"800px"}}onClick={() => HandleClickOnImage()} */