import React, { Component } from 'react';
import { useState, useEffect, useRef } from 'react'
import { setCookie, getCookie } from './BJCookies.js';
import { XLog } from './SiteUsers.js';
 
import { Modal1 } from './Modal1'
import { Link } from 'react-router-dom';
import { XImage, XImageSlider } from './XImage.js';
import { Cams } from './Cams'
 
  
export function Home(props) {
    var displayName = Home.name ;
    document.title = 'Home';
  
    //const [scale, setScale] = useState(50);
    //const [data, setData] = useState();
    const [pics, setPics] = useState([]);
    const divRef = useRef(null);
    XLog();
   
    useEffect(() => {
         setPics(["/Image/BerndsPics/BJJ_0180.JPG",
            "/Image/BerndsPics/DSC_0339_01.JPG",
            "/Image/BerndsPics/DSC_0341.JPG",
            "/Image/BerndsPics/_BJJ1201.JPG",
            "/Image/BerndsPics/DSC_0408.JPG",
            "/Image/BerndsPics/DSC_0428_01.JPG",
            "/Image/BerndsPics/DSC_0442.JPG",
            "/Image/BerndsPics/_BJJ0209.JPG",
            "/Image/BerndsPics/_BJJ0251.JPG",
            "/Image/BerndsPics/_BJJ0306_01.JPG",
            "/Image/BerndsPics/_BJJ0379.JPG",
            "/Image/BerndsPics/_BJJ0399.JPG",
            "/Image/BerndsPics/_BJJ0427.JPG",
            "/Image/BerndsPics/_BJJ0438.JPG",
            "/Image/BerndsPics/_BJJ0588_01.JPG",
            "/Image/BerndsPics/_BJJ0598.JPG",
            "/Image/BerndsPics/BJJ_0067.JPG",
            "/Image/BerndsPics/BJJ_0068_01.JPG",
            "/Image/BerndsPics/BJJ_0149_01.JPG",
            "/Image/BerndsPics/_BJJ1261.JPG",
            "/Image/BerndsPics/BJJ_0151.JPG",
            "/Image/BerndsPics/_BJJ1248.JPG", 
            "/Image/BerndsPics/_BJJ1205.JPG",
            "/Image/BerndsPics/_BJJ1207.JPG",
            "/Image/BerndsPics/_BJJ1212.JPG",
            "/Image/BerndsPics/_BJJ1216.JPG",
            "/Image/BerndsPics/_BJJ1219.JPG",
            "/Image/BerndsPics/_BJJ1225.JPG",
            "/Image/BerndsPics/_BJJ1226.JPG",
            "/Image/BerndsPics/_BJJ1230.JPG",
            "/Image/BerndsPics/_BJJ1232.JPG",
             "/Image/BerndsPics/_BJJ1236.JPG",
             "/Image/BerndsPics/_BJJ1623.jpg",
             //"Image/JobXWant.jpg",
            //"Image/JobZWant.jpg",
                "/Image/BerndsPics/_BJJ1246.JPG"].sort(() => Math.random() - 0.5));
        if (divRef.current) {
            const offsetBottom = divRef.current.offsetTop + divRef.current.offsetHeight;
            window.scrollTo({ top: offsetBottom });
        }
       // divRef.current.scrollIntoView({ behavior: 'smooth' })
        
    }, []);

    //const scroll = () => {
    //    const section = document.querySelector('#divrefId');
    //    section.scrollTo({
    //        top: 500,
    //        left: 0,
    //        behavior: 'smooth'
    //    });
    //};
    return (<>
          
            <div id="divrefId" style={{ backgroundColor: "white" }} className="col-lg-12 col-md-12 col-sm-12 col-xs-12 homeImages">
              
            <div className="imgcnt" ref={divRef} >
                <XImageSlider images={pics} size="25" t='3000' w='true' width='100' />  </div>   
        </div>
  </>);
  
}
