import React, { Component } from 'react';
import { useState, useEffect, useRef } from 'react'
import './ScriptureStyles.css';
import { setCookie, getCookie } from './BJCookies.js';


export function XLog() {
useEffect(() => {

    fetch('weatherforecast/GetIt');
    //     .then((res) => res.json())
    //       .then((data) => {
    //      // setData(data);
    //       //setSW(data)
    //}
    // );  

}, []);
    return (<>
        </>
        )
}