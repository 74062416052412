import React from 'react';
import { useState, useEffect, useRef } from 'react'


export   function XeLog(props) {
    const [logItems, setLogItems] = useState([]);
    const [name, setName] = useState("");
    const [check, setCheck] = useState(false);
    const [nameX, setNameX] = useState([]);
    useEffect(() => {

       
        fetch('weatherforecast/GetLog')
            .then((res) => res.json())
            .then((data) => {
                setLogItems(data);
            });

        fetch('weatherforecast/GetC')
            .then((res) => res.json())
            .then((data) => {
                setNameX(data);
            });
           }, []);
   
    function checkName(arg) {
        if (arg === nameX[3])
            setCheck(true);
    }
    return(<div>
       <input value={ name} onChange={(e)=>setName(e.target.value)} type="text" />
            <button className="btn btn-lg mysearchButton btn-primary" onClick={ ()=>checkName(name)}></button>
           <div><table><thead></thead>
            {check ? logItems.map((item, index) => <tr key={index}><td>{item.index}</td><td> {item.added}</td><td>{item.text}</td></tr>)
           :""}</table></div></div>)
}
