import { Modal1 } from './Modal1'
import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { setCookie, getCookie } from './BJCookies.js';
function GetSpan() {

}
 
export function Footer(props) {
    const host = window.location.protocol + "//" + window.location.hostname + " ";
    const [showLog, setShowLog] = useState(false);
    
    let diffTime = Math.abs(new Date().valueOf() - new Date("May 29, 2023 08:00").valueOf());
    let days = diffTime / (24 * 60 * 60 * 1000);
    let hours = (days % 1) * 24;
    let minutes = (hours % 1) * 60;
    let secs = (minutes % 1) * 60;
    [days, hours, minutes, secs] = [Math.floor(days), Math.floor(hours), Math.floor(minutes), Math.floor(secs)]

   // console.log(days + 'd', hours + 'h', minutes + 'm', secs + 's');
   // var diffDays = electionDay - today; 
    return (
        <div> 
            <footer className="  footer myfooter">
               
                {/*{!getCookie('cc') ? <button onClick={() => { setCookie("cc", "1", 365); setA(1); } }*/}
                {/*    className="btn btn btn-success  btn-block">Accept Cookies</button>:""} */}
                &copy; Bernd Jochims 2022 - {host} Powered by.NET 6 Web API, C#, SQL, React 18 ...
            
                
        </footer>    
            </div>)
}
{/*    <span className="election">Alberta election in   {days} Days    {hours} Hours</span> .*/}