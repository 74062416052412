import React, { Component } from 'react';
import { useState, useEffect, useRef } from 'react'
import { XLog } from './SiteUsers.js';
import { setCookie, getCookie } from './BJCookies.js';
import './ScriptureStyles.css';

export    function Quotes(props)
{
  /*  var displayName = Quotes.name;*/
    const [count, setCount] = useState();
    const [Quotes, loadQuotes] = useState([]);
    const [SQuotes, loadSQuotes] = useState([]);
    const [Authors, loadAuthors] = useState([]);
    
    const [refresh, setRefresh] = useState(0);
    const qRef = useRef(null);
    XLog();
    useEffect(() =>
    {
               fetch('weatherforecast/GetQuotes')
            .then((res) => res.json())
                   .then((data) =>
                   {

                       loadQuotes(data);
                       
                   }).then(() => { SetScroll();  }
                      
                   ); 
        fetch('weatherforecast/GetAuthors')
            .then((res) => res.json())
            .then((data) => {

                loadAuthors(data);

            }).then(() => { SetScroll(); }); 
        document.title = 'Quotes';

    }, [refresh]); 
    function SetScroll() {
        let wrap = document.querySelector("#qwrapper");
        wrap.scrollTop = 0;

    }
    function ReloadButton(props) {


        return (<div className="reloadBtn"><button onClick={() =>
            setRefresh(b => b + 1) 
          // SetScroll() 

        }>

            More Quotes
        </button></div>
        )
    }
    
    function ShowQuotes() {
  
            
        return (<div id="qwrapper" ref={qRef}>
            <div className="table-striped table-bordered"  >
                <ul >
                    {Quotes.map((q,  index  ) => 
                        <li key={index}>
                        <div className="QuoteBox">
                            <span className="aname">
                                <a href={"https://en.wikipedia.org/wiki/"+ q.author} target="_blank">{q.author}</a>
                            </span>  <span className="quoteText  col-sm-12"  >
                                {q.body}

                            </span>

                        </div>
                    </li>
                    )}
            </ul>

            </div>
            </div>)
    }
    function ShowSQuotes() {
     
        return (<div>
            <div >
                <ul  >
                    {SQuotes.map((q, index) =>
                        <li key={index}>
                            <div className="QuoteBox">
                                <div className="aname">
                                    <a href={"https://en.wikipedia.org/wiki/" + q.author} target="_blank">{q.author}</a>
                            </div>  <span className="quoteText  col-sm-12"  >
                                    {q.body}

                                </span>

                            </div>
                        </li>
                    )}
                </ul>

            </div>
        </div>)
    }
    function LeftMenu() {
        return (
            <div className="lmenu sticky left2 col-md-12 col-xs-12 col-sm-12">
            <div  >
     
            {Authors.map((a, index) =>
                <button className=" btn btn-block btn-outline-primary myBtn col-md-12 col-xs-12 col-sm-12 col-lg-12" key={index} onClick={()=> GetQuoteByAuthor(a.fullname)  }>


                    <div><span className="badge badge-dark bg-success badge-pil fleft">{a.articles}</span> {a.fullname}</div>




                </button>
            )}
 </div>
            </div>)
    }

   
    function GetQuoteByAuthor(arg) {
        let url = "weatherforecast/GetQuotesByAuthor?arg=" + encodeURIComponent(arg);
      //  console.log("url" + url);

        fetch(url).then((res) => res.json())
            .then((data) => {
             //   console.log("getauth" + data);
                loadSQuotes(data);
            }).then(() => { SetScroll(); });
    
    }
        return ( 
            <div >
              {/*<div><button onClick={() => setShowLog(!showLog)}>{!showLog ? "Show Log" : "Hide Log"}</button>*/}
              {/*    {showLog ? <InnerQ /> : null}*/}
              {/*  </div>*/}
                <div className="gwrapper col-lg-12" id="wrapper" style={{ overflowX: "hidden" }}>
                    <div className="row">
                    <div className=" col-sm-12 sticky  col-lg-2"  >   <LeftMenu /></div>
                        <div className="col-lg-8 col-sm-12 quoteBox" >{  <ShowSQuotes />  } <ShowQuotes />    <ReloadButton /> </div>
                </div>    
                   </div></div>
       )
    }  