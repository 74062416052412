import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { Cams } from './components/Cams';
import { Quotes } from './components/Quotes';
import { XeReX } from './components/XeReX';
import { Scriptures } from './components/Scriptures';
import { NotFound } from './components/404';
import {About } from './components/About';
import { XeLog } from './components/XeLog'; 
import './custom.css'
import { Switch } from 'react-router-dom';

export default class App extends Component {
  static displayName = App.name;

    render() {
        return (
            <Layout>
                <Switch>
                <Route exact path='/' component={Home} />
                <Route path='/cams' component={Cams} />
                <Route path='/quotes' component={Quotes} />
                    <Route path='/xerex' component={XeReX} />
                    <Route path='/about' component={About} />
                    <Route path='/xelog' component={XeLog} />
                <Route path='/scriptures' component={Scriptures} />
                <Route path='*'  component ={NotFound } />
             </Switch>
      </Layout>
    );
  }
}

{/*<img src="/image/404.jpg" alt="404 Not found"/>   <Redirect from="*" to path="/" />*/ }
{/*  <Redirect to="/" />*/ }
{/*<Route exact path="/">*/ }
{/*    <Redirect to='/scriptures' />*/ }
{/*</Route>*/ }