import React from 'react';
import { useState, useEffect, useRef } from 'react'
import './ScriptureStyles.css';
import { XImage, XImageSlider } from './XImage.js';

export function XeReX(props) {
    const [qa, setQA] = useState("");
    const [qb, setQB] = useState("");
    const [c, setc] = useState("");
    const [auth, isAuthOK] = useState( );
    function AddQuote(e) {
    
        console.log('Addquote' + qa + qb);
        if (auth === "BJJ") { 
        let url = "weatherforecast/AddQuotes?a="+qa+"&b="+qb;
        fetch(url).then((res) => res.json())
            .then((data) => {
                setc(data);
      });
    }  }
       
    const ImageArray = [];
    ImageArray[0] = "/Image/Screenshot 2022-06-29 085210.png";
    ImageArray[1] = "/Image/_BJJ0773_02.JPG";
    ImageArray[2] = "/Image/_BJJ1086.JPG";
    return (<div>
        <h1>  {qa}{qb}   add more {c }</h1>
        <div> 
            <input type="text" value={auth} placeholder="user" onChange={(e) => isAuthOK(e.target.value)} />
            <p />
            <input type="text" value={qa} placeholder="Author" onChange={(e) => setQA(    e.target.value )}/>
            <p />
            <textarea rows="40" cols="80" value={qb} placeholder="Quote" onChange={(e) => setQB(     e.target.value  )} />
            <p />   <input type="submit" onClick={(e)=>AddQuote(e)  } value="Add Quote"/><p />
        </div>
     
    </div>
        )
} 