import React, { Component } from 'react';
import { useState, useEffect, useRef } from 'react'
import { XLog } from './SiteUsers.js';
import { setCookie, getCookie } from './BJCookies.js';
import './ScriptureStyles.css';
export function NotFound(props) {

    return (
        <div> <img src="/image/404.jpg" alt="" /></div>
        )
}
