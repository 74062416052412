import React, { Component } from 'react';
import { useState, useEffect, useRef } from 'react'
import { setCookie, getCookie } from './BJCookies.js';
import { XLog } from './SiteUsers.js';

import { Modal1 } from './Modal1'
import { Link } from 'react-router-dom';
import { XImage, XImageSlider } from './XImage.js';
/*import {MyLink } from './myLink'*/
import { Cams } from './Cams'
//function ImgCar(props) {


//    return(
//    <div>
//        <div id="c2" className="carousel slide carousel-fade" data-ride="carousel" style="margin-bottom:50px;">
//            <div className="carousel-inner" style="background:#000000;">
//                <div className="carousel-item active">
//                    <img className="d-block w-100" src="/image/Rem2/1307426751_855784c268_o.jpg" alt="First slide">
//                </div>

//                <div>
//                    @foreach (var item in @ViewBag.fileinfo)
//                    {

//                        <div className="  carousel-item  carousel-fade  ">
//                            <img className="d-block   w-auto img-fluid  " style="        object-fit: cover;
//        height: 95vh;
//" img src="@item.Remove(0, 7)" />
//                        </div>
//                    }
//                </div>

//                <a className="carousel-control-prev" href="#c2" role="button" data-slide="prev">
//                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
//                    <span className="sr-only">Previous</span>
//                </a>
//                <a className="carousel-control-next" href="#c2" role="button" data-slide="next">
//                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
//                    <span className="sr-only">Next</span>
//                </a>
//            </div>
//        </div>
//            )
//        }

export function MyCard(props) {

    // text = text.split('\n');
    return (
        <div className="col-lg-4 col-med-12 col-sm-12 col-xs-12 mainColumns outerBorder" style={{ backgroundColor: props.CardColor }}>
            <div className="card h-100  innerBorder" >
                <div className="card-header card-style1 borderRadius5">
                    <h5 className="card-title" style={{ textAlign: 'center' }}>{props.CardTitle}  </h5>
                </div>
                <div className="card-body " style={{ marginTop: "-27px" } }>
                    <div className="card-img-top boxHeight"  >
                        <XImageSlider images={props.Pics} size={props.PSize} t={props.t} width={props.width} BoxHeight={props.BoxHeight } /> 
                  
                    </div>
                    <div className="card-text"><h5>
                        {props.CardTextLine1}</h5>
                        <p />
                       <h6> {props.CardTextLine2}</h6>
                        <p />
                        <h6>  {props.CardTextLine3}</h6>

                        <span>
                            {
                                props.CardLinkTo1 ? <Link className="myLink btn btn-block btn-outline-primary" to={props.CardLinkTo1}>{props.CardLinkText1}</Link> : ""
                            }
                        </span>
                        <span >
                            {
                                props.CardLinkTo2 ? <Link className="myLink btn btn-block btn-outline-primary" to={props.CardLinkTo2}>{props.CardLinkText2}</Link> : ""
                            }
                        </span>
                        <span>
                            {
                                props.CardLinkTo3 ? <Link className="myLink btn btn-block btn-outline-primary" to={props.CardLinkTo3}>{props.CardLinkText3}</Link> : ""
                            }
                        </span>

                        <div>
                            {
                                props.CardLink ? <div className="myLink btn btn-block btn-outline-primary myBtn"
                                    onClick={() => {
                                       /* if (window.confirm("Warning:\n http://649stats.ca, the site being opened has no SSL HTTPS certificate. \n\nClick on OK to proceed to the website or Cancel."))*/
                                       /* {*/
                                            window.open(props.CardLink, "_blank")
                                      /*  }*/
                                    }}>
                                    {props.CardLabel}</div> : ""
                            }
                        </div>
                        <div>
                            {
                                props.CardLink2 ? <div className="myLink btn btn-block btn-outline-primary myBtn" onClick={() => { window.open(props.CardLink2, "_blank") }}>  {props.CardLabel2}</div> : ""
                            }
                        </div>
                    </div>

                </div>
                {props.CardFooterContent ?
                    <div className="card-footer  borderRadius5 card-style2">
                        {props.CardFooterContent}
                    </div>
                    : null
                }
            </div>
        </div>
    )
}
export function About() {
    var displayName = About.name;
    document.title = 'About Bernd Jochims';
    const [data, setData] = useState();
    const [pics, setPics] = useState([]);
    const [pics2, setPics2] = useState([]);
    const [pics3, setPics3] = useState([]);
    //const ImageArray = [];
    ////ImageArray[0] = "/Image/Screenshot 2022-06-29 085210.png";
    ////ImageArray[1] = "/Image/_BJJ0773_02.JPG";
    //ImageArray[0] = "/Image/BerndsPics/_BJJ1082_02.JPG";
    //ImageArray[1] = "/Image/BerndsPics/_BJJ1085.JPG";
    //ImageArray[2] = "/Image/BerndsPics/_BJJ1086_01.JPG";
    //ImageArray[3] = "/Image/BerndsPics/_BJJ1069_01.JPG";
    XLog();
    //const ImageArrayCar = [
       
    //"Image/BerndsPics/_BJJ0772.JPG",
    //"Image/BerndsPics/_BJJ0777_01.JPG",
    //    "Image/BerndsPics/_BJJ0767_02.JPG",
    //      "Image/BerndsPics/_BJJ0319_01.JPG",
    //    "Image/BerndsPics/_BJJ0770_03.JPG"];
    //const ImageArrayBernd = ["Image/JobXWant.jpg",
    //       "Image/JobZWant.jpg",
    //  /*  "Image/JobWant3.jpg",*/
        
    //    "Image/JobWanted2.jpg",
       
     
   
    //    "/image/bernd480.jpg"];
    useEffect(() => {
      setPics3([  //"Image/JobXWant.jpg",
        //    "Image/JobZWant.jpg",
        //    "Image/JobWanted2.jpg",
          "/Image/BerndsPics/_BJJ0438.JPG", "/Image/BerndsPics/_BJJ0427.JPG", "Image/BerndsPics/_BJJ0319_01.JPG"]
        );
        setPics2(["Image/BerndsPics/_BJJ0772.JPG",
            "Image/BerndsPics/_BJJ0777_01.JPG",
            "Image/BerndsPics/_BJJ0767_02.JPG",
            "Image/BerndsPics/_BJJ0319_01.JPG",
            "Image/BerndsPics/_BJJ0770_03.JPG"]);
           setPics( ["/Image/BerndsPics/_BJJ1082_02.JPG",
           "/Image/BerndsPics/_BJJ1085.JPG",
            "/Image/BerndsPics/_BJJ1086_01.JPG",
            "/Image/BerndsPics/_BJJ1069_01.JPG"]);
        



    }, []);

    
    return (
        <>
         
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 container">
                <div className="row">
                    <MyCard CardColor="#e5f6eb"
                        CardTextLine1={<a href='mailto:bernie@berndjochims.ca'
                            className='btn btn-primary card-header marginTop25'  > Contact </a>}

                        CardTitle="Contact Bernie"
                        CardLabel="Demo: 649stats.ca SQL ASP.NET MVC WebForms"
                        CardLink="http://lotto649stats.ca"
                        CardFooterContent="Learned Turbo C++ 1992"
                        Pics={pics3} PSize="2" t="2500" w='false' width='100'
                    />
                   <MyCard  
                        CardColor="#f3f2fc" CardTextLine1=
                        "React 18 Demo with .NET 6 - SQL Server  "
                        CardTextLine2="Click below to experience how responsive React 18 can be."
                        CardLinkTo1="/cams" CardLinkText1="Cams"
                        CardLinkTo2="/quotes" CardLinkText2="Quotes"
                        CardLinkTo3="/scriptures" CardLinkText3="Scriptures"
                        CardTitle="React JS Demo"
                        CardFooterContent="Learned React 2021"
                        Pics={pics} PSize="3" t='2000' w='false' width='100'  
                    />
                    <MyCard
                       
                        CardTitle="Job Search" CardColor="#e2fbfd"
                        CardTextLine1=" Retired too early, now I want to get back to working again. "
                        CardTextLine3=" I am seeking work in Calgary or Vancouver BC developing web applications with .net, SQL Server and React.js..."
                        CardTextLine2=" This demo was made with SQL server, React 18.2, and .NET 6 Web API"
                       
                        CardLabel2="Demo: .NET 5 MVC  SQL angularjs"
                        CardLink2="http://berndjochims.com"
                        Pics={pics2} PSize="4" t='2500' w='false' width='100'  
CardFooterContent="Learned C#.NET 2002 "
                    />

                 


                </div>
                {/*<iframe width="1170" height="658" src="https://www.youtube.com/embed/rPoobn4jhRI" title="meJob" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>*/}

            </div>
        </>);

}
