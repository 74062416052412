import React, { Component } from 'react';
import { useState  } from 'react'
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { Footer } from './Footer';
import { setCookie, getCookie } from './BJCookies.js';
import { useEffect } from 'react';
export function Layout(props) {
    const [a, setA] = useState(0);
    //export class Layout extends Component {
    var displayName = Layout.name;

    //useEffect(() => {

    //    // remove after developement. 
       
 
    //}, []);


    var rxz = '';
    const id = Date.now();
    if (a === 1) {
        if (!getCookie("_rxz")) {
            setCookie("_rxz", "X" + id + "X", 365);

        }
    }

 
  //  console.log("location" + props.location.pathname);
    /* render () {*/
    return (
        <div className="col-lg-12 col-sm-12 /* container*/ ">
           
        <NavMenu />
          {/*     <Container >   */} <div className ="col-lg-12 col-sm-12 col-md-12  ">
                {!getCookie('cc') ? <button onClick={() => { setCookie("cc", "1", 365); setA(1);  }}
                      className="btn btn btn-success  btn-block">Accept cookies by clicking here.</button> : ""}
                  {props.children}
         {/*</Container>     */}</div>
              <Footer />
            
      </div>
    );
  }
/*}*/
